<template>
  <b-card :title="titleForm">
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>
          <!-- first name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="firstName"
              rules="required"
            >
              <b-form-group label="Primeiro Nome" label-for="v-firstName">
                <b-form-input
                  id="v-firstName"
                  v-model="form.firstName"
                  :state="errors.length > 0 ? false : null"
                  placeholder="descrição"
                  :disabled="
                    (!verificarPermissao('ROLE_USER_ATUALIZAR') && !!form.id) ||
                    (!verificarPermissao('ROLE_USER_INSERIR') && !form.id)
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="lastName"
              rules="required"
            >
              <b-form-group label="Ultimo Nome" label-for="v-lastName">
                <b-form-input
                  id="v-lastName"
                  v-model="form.lastName"
                  :state="errors.length > 0 ? false : null"
                  placeholder="descrição"
                  :disabled="
                    (!verificarPermissao('ROLE_USER_ATUALIZAR') && !!form.id) ||
                    (!verificarPermissao('ROLE_USER_INSERIR') && !form.id)
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Nome Completo" label-for="v-nomeCompleto">
              <b-form-input
                id="v-nomeCompleto"
                v-model="form.nomeCompleto"
                placeholder="nome Completo"
                :disabled="
                  (!verificarPermissao('ROLE_USER_ATUALIZAR') && !!form.id) ||
                  (!verificarPermissao('ROLE_USER_INSERIR') && !form.id)
                "
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-group label="Email" label-for="v-email">
                <b-form-input
                  id="v-email"
                  v-model="form.email"
                  type="email"
                  placeholder="email"
                  :state="errors.length > 0 ? false : null"
                  :disabled="
                    (!verificarPermissao('ROLE_USER_ATUALIZAR') && !!form.id) ||
                    (!verificarPermissao('ROLE_USER_INSERIR') && !form.id)
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- email -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Perfil"
              rules="required"
            >
              <b-form-group label="Perfil" label-for="v-role">
                <v-select
                  v-model="form.perfil"
                  :reduce="(comboPerfils) => comboPerfils.id"
                  label="nome"
                  :options="comboPerfils"
                  :state="errors.length > 0 ? false : null"
                  :disabled="
                    (!verificarPermissao('ROLE_USER_ATUALIZAR') && !!form.id) ||
                    (!verificarPermissao('ROLE_USER_INSERIR') && !form.id)
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col v-if="form.id" cols="12">
            <b-form-group label="assinatura" label-for="v-assinatura">
              <b-form-file
                v-model="assinatura"
                placeholder="Selecione um arquivo..."
                drop-placeholder="Drop file here..."
                browse-text="Procurar"
                accept="image/jpeg"
              />
            </b-form-group>
          </b-col>

          <b-col v-if="form.id" cols="12">
            <b-form-group>
              <b-form-checkbox
                v-model="form.ativo"
                :checked="form.ativo"
                name="check-button"
                switch
                inline
                :disabled="
                  (!verificarPermissao('ROLE_USER_ATUALIZAR') && !!form.id) ||
                  (!verificarPermissao('ROLE_USER_INSERIR') && !form.id)
                "
              >
                {{ form.ativo ? 'Sim' : 'Não' }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <div class="image-wrapper">
              <img
                v-if="form.assinatura"
                :src="form.assinatura"
                alt="Imagem carregada"
                class="responsive-image"
              />
            </div>
          </b-col>

          <!-- submit and reset -->
          <ButtonsFormVue
            :permission-insert="verificarPermissao('ROLE_USER_INSERIR')"
            :permission-edit="verificarPermissao('ROLE_USER_ATUALIZAR')"
            :disable-form="false"
            @save="save()"
            @cancel="cancel()"
          />
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BFormCheckbox,
  BFormFile
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { verificarPermissao } from '@/auth/utils'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'
import mixinsGlobal from '@/mixins'

export default {
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('usersSettingAccessModuleModule', {
      user: (state) => state.user
    }),
    ...mapState('combosModule', {
      comboPerfils: (state) => state.comboPerfils
    })
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    vSelect,
    ButtonsFormVue,
    BFormFile
  },
  directives: {
    Ripple
  },
  watch: {
    assinatura() {
      this.updateAssinatura(this.assinatura)
    }
  },
  data() {
    return {
      verificarPermissao,
      titleForm: 'Cadastro de Usuarios',
      required,
      form: {
        firstName: '',
        lastName: '',
        nomeCompleto: '',
        email: '',
        password: '',
        perfil: [
          {
            id: ''
          }
        ],
        assinatura: null
      },
      assinatura: null
    }
  },
  mounted() {
    this.form = JSON.parse(JSON.stringify(this.user))
    this.form.assinatura = `data:image/jpeg;base64,${this.form.assinatura}`
    this.$store.dispatch('combosModule/loadComboPerfils')
  },
  methods: {
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
          if (encoded.length % 4 > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4))
          }
          resolve(encoded)
        }
        reader.onerror = (error) => reject(error)
      })
    },
    async updateAssinatura(file) {
      this.form.assinatura = await this.getBase64(file)
      this.form.assinatura = `data:image/jpeg;base64,${this.form.assinatura}`
    },
    cancel() {
      this.$emit('cancelar')
      this.cleanObjeto()
      this.$store.dispatch('usersSettingAccessModuleModule/resetUser')
    },
    insertAssinatura() {},
    save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          if (this.form.id) {
            let data = {
              id: this.form.id,
              assinatura: this.assinatura
            }
            this.$store
              .dispatch('usersSettingAccessModuleModule/insertAssinatura', data)
              .then((res) => {
                if (res.error) {
                  this.MensagemError('Falha ao Editar', response.message)
                  return
                }
                this.$store
                  .dispatch(
                    'usersSettingAccessModuleModule/editUser',
                    this.form
                  )
                  .then((response) => {
                    if (response.response) {
                      this.MensagemError(
                        'Falha ao Editar',
                        response.response.data.error
                      )
                      return
                    }
                    this.showMessageSuccess('Editar', 'Editado com sucesso!')
                    this.$refs.form.reset()
                    this.cancel()
                  })
              })
          } else {
            this.$store
              .dispatch(
                'usersSettingAccessModuleModule/insertNewUser',
                this.form
              )
              .then((response) => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Inserir',
                    response.response.data.error
                  )
                  return
                }
                this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
                this.cleanObjeto()
                this.$refs.form.reset()
              })
          }
        }
      })
    },
    cleanObjeto() {
      this.form = {
        firstName: '',
        lastName: '',
        nomeCompleto: '',
        email: '',
        password: '',
        perfil: [
          {
            id: ''
          }
        ]
      }
    }
  }
}
</script>
<style scoped>
.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px; /* Limita a largura máxima do contêiner */
  max-height: 300px; /* Limita a altura máxima do contêiner */
  overflow: hidden; /* Esconde partes excedentes da imagem */
  margin: auto; /* Centraliza horizontalmente */
}

.responsive-image {
  max-width: 100%; /* Garante que a imagem não ultrapasse a largura do contêiner */
  max-height: 100%; /* Garante que a imagem não ultrapasse a altura do contêiner */
  object-fit: contain; /* Mantém a proporção da imagem */
  border-radius: 8px; /* Adiciona bordas arredondadas */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adiciona uma sombra leve */
}
</style>
